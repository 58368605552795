import React from 'react';
import {Link} from 'react-router-dom';
import {Illness, IllnessInfo} from "../../models/IllnessDataBase";
import Accordion from 'react-bootstrap/Accordion'
import '../../res/scss/illness.scss'

interface IllnessDataBaseViewProps {
    illness: [IllnessInfo] | null,
    deleteIllness: (illnessId: bigint) => void,
    createSubgroup: (illness: Illness) => void,
    message: string | null
}

class IllnessDataBaseView extends React.Component<IllnessDataBaseViewProps> {

    public state = { currentKeys: [] as string[] }

    public renderContent(): React.ReactNode {
        if (!this.props.illness) {
            return <div/>;
        }

        return (
            <Accordion>
                {this.renderIllness(this.props.illness)}
            </Accordion>
        );
    }

    private renderIllness(illnessInfoArray: [IllnessInfo]) {
        return (
            illnessInfoArray.map((illnessInfo) => {
                const className = illnessInfo.subGroups.length < 1 ? 'not-children' : ''
                const illnesId = illnessInfo.illness.oid.toString()
                return (
                    <Accordion.Item eventKey={illnesId} style={{border: "none"}}>
                        <div style={{ display: "flex", justifyContent: "space-between", flexFlow: 'row wrap'}}>
                            <Accordion.Header style={{ border: "none", background: "none", fontSize: "15pt" }}>
                                <div className={className}>
                                    {illnessInfo.illness.name}
                                </div>
                            </Accordion.Header>
                            <div style={{ display: "flex", justifyContent: "space-between", flexFlow: 'row wrap'}}>
                                <div style={{marginRight: '1em'}}>
                                    {illnessInfo.themes.length} {illnessInfo.themes.length == 1 ? "entrada" : "entradas"}
                                </div>
                                {this.renderActions(illnessInfo)}
                            </div>
                        </div>
                        {this.renderAccordionBody(illnessInfo.subGroups)}
                    </Accordion.Item>
                )
            })
        );
    }

    private renderAccordionBody(subGroups: [IllnessInfo]) {
        if (subGroups.length < 1) {
            return <div/>
        }

        return (
            <Accordion.Body>
                <Accordion style={{marginLeft: "2em"}}>
                    {this.renderIllness(subGroups)}
                </Accordion>
            </Accordion.Body>
        );
    }

    public renderActions(illnessInfo: IllnessInfo): React.ReactNode {
        const illness = illnessInfo.illness
        const color = illnessInfo.subGroups.length < 1 ? 'gray' : 'black'
        return (
            <div style={{padding: ".2em"}}>
                <i className='fa fa-plus' style={{color: color, padding: ".2em", cursor: "pointer"}}
                   onClick={() => this.props.createSubgroup(illness)}/>
                <Link style={{color: color, padding: ".2em"}} to={`/illness/view/${illness.oid}`}
                      onClick={(action) => {
                          action.preventDefault()
                          window.location.href = `/illness/view/${illness.oid}`;
                      }}>
                    <i className='fa fa-eye'/>
                </Link>
                <Link style={{color: color, padding: ".2em"}} to={`/illness/edit/${illness.oid}`}
                      onClick={(action) => {
                          action.preventDefault()
                          window.location.href = `/illness/edit/${illness.oid}`;
                      }}>
                    <i className='fa fa-edit'/>
                </Link>
                <i style={{color: color, padding: ".2em", cursor: "pointer"}} className='fa fa-trash'
                   onClick={() => this.props.deleteIllness(illness.oid)}/>
            </div>
        );
    }

    public render(): React.ReactNode {

        if (!this.props.illness) {
            return <></>
        }

        if (Object.keys(this.props.illness).length > 0) {
            return (
                <div>
                    {this.renderMessageCard()}
                    <style>
                        {".accordion-button {border: none;background: none}"}
                    </style>
                    <div className="col-md-12">
                        <div className="card shadow p-3">
                            <div className="card-body">
                                {this.renderContent()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return <span>No se han encontrado resutaldos</span>
    }

    private renderMessageCard() {
        if (this.props.message != null) {
            return this.renderMessage(this.props.message);
        }

        return null
    }

    private renderMessage(message: string | null, style: {} = {}) {
        return (
            <div className="col-md-12">
                <div className="card shadow p-3">
                    <div className="card-body">
                        <div className="message">
                            <label style={style}>{message}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IllnessDataBaseView;